// First import your theme colors
$theme-colors: (
  dark: (
    bg-main: #282c34,
    bg-surface: #343a40,
    bg-surface-darker: #1f242b,
    bg-hover: #495057,
    border-success: #28a745,
  ),
  light: (
    bg-main: #f8f9fa,
    bg-surface: #dee2e6,
    bg-surface-darker: #e9ecef,
    bg-hover: #ced4da,
    bg-disabled: #adb5bd,
    text-warning: #d39e00,
    border-success: #28a745,
  ),
);

@function theme-color($theme, $color-key) {
  @return map-get(map-get($theme-colors, $theme), $color-key);
}

.btn.btn-primary.nav-item {
  &:hover {
    color: white !important;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

// Navbar Styles
.brand-name {
  font-family: 'Protest Revolution', cursive;
  font-size: 1.5rem;
  color: theme-color(light, bg-main);
  font-weight: bold;
  letter-spacing: 0.05em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; // High z-index to keep it above other content
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  .navbar-brand {
    img {
      height: 30px;
      width: auto;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // Mobile styles
  @media (max-width: 991px) {
    .navbar-collapse {
      padding: 1rem;
      margin-top: 0.5rem;
      border-radius: 8px;
      background: theme-color(dark, bg-surface);

      &.show {
        background: theme-color(dark, bg-surface);
      }
    }

    .navbar-nav {
      .nav-item {
        padding: 0.5rem 0;
      }
    }

    .dropdown-menu {
      border: none;
      background: theme-color(dark, bg-surface-darker);
      padding-left: 1rem;

      .dropdown-item {
        padding: 0.5rem 1rem;
        color: theme-color(light, bg-main);

        &:hover {
          background: theme-color(dark, bg-hover);
        }
      }
    }
  }
}

// Dropdown styles
.dropdown-menu {
  background: theme-color(dark, bg-surface);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid theme-color(dark, bg-surface-darker);

  .dropdown-item {
    color: theme-color(light, bg-main);
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;

    &:hover {
      background: theme-color(dark, bg-hover);
      transform: translateX(5px);
    }
  }
}

// Theme switch styles
.form-check {
  .form-check-input {
    cursor: pointer;
    width: 3rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease;

    &:checked {
      background-color: theme-color(light, border-success);
      border-color: theme-color(light, border-success);
    }
  }
}

// Button styles
.btn-outline-secondary {
  padding: 0.375rem 0.75rem;
  transition: all 0.2s ease;
  border-color: theme-color(light, border-success);
  color: theme-color(light, bg-main);

  &:hover {
    background: theme-color(dark, bg-hover);
    transform: translateY(-1px);
  }
}

// Search modal styles
.modal {
  .modal-content {
    background: theme-color(dark, bg-surface);
    border-radius: 12px;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    border-bottom: 1px solid theme-color(dark, bg-surface-darker);
    color: theme-color(light, bg-main);
  }

  .form-control {
    background: theme-color(dark, bg-surface-darker);
    border-color: theme-color(dark, bg-hover);
    color: theme-color(light, bg-main);

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
      border-color: theme-color(light, border-success);
    }
  }
}

// Dark mode specific styles
.dark-mode {
  .navbar {
    background-color: theme-color(dark, bg-main);

    .nav-link {
      color: theme-color(light, bg-surface);

      &:hover {
        color: theme-color(light, bg-main);
      }
    }
  }
}

// Theme switch icons
.theme-icon {
  transition: color 0.3s ease;

  &.light {
    color: theme-color(light, bg-main);
  }

  &.dark {
    color: theme-color(dark, bg-surface-darker);
  }
}

// Animation keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Remove default box-shadow and change border color to success for all form elements */
.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  border-color: var(--bs-success) !important;
  box-shadow: none !important;
}

/* Add a custom box-shadow with success color if desired */
.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25) !important;
}

/* Specific styles for checkbox and radio when checked */
.form-check-input:checked {
  background-color: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
}

/* Specific styles for toggle switch (form-switch) */
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
  background-color: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}
