// $theme-colors: (
//     dark: (
//         bg-main: #282c34,
//         bg-surface: #343a40,
//         bg-surface-darker: #1f242b,
//         bg-hover: #495057,
//         border-success: #28a745,
//     ),
//     light: (
//         bg-main: #f8f9fa,
//         bg-surface: #dee2e6,
//         bg-surface-darker: #e9ecef,
//         bg-hover: #ced4da,
//         bg-disabled: #adb5bd,
//         text-warning: #d39e00,
//         border-success: #28a745,
//     ),
// );

[data-bs-theme='dark'] {
  .bg-main,
  .App {
    // Rich gradient with deeper contrast
    background: linear-gradient(to right, #141519, #1e2024);
  }

  .bg-surface {
    background-color: #2a2d35 !important; // Softer dark surface
  }

  .bg-surface-darker {
    background-color: #1a1c22 !important; // Deeper dark for contrast
  }

  .bg-hover {
    background-color: #363a45 !important; // Subtle hover state
  }

  .text-primary {
    color: #f0f2f5 !important; // Softer white for better readability
  }

  .border-success {
    border-color: rgba(240, 242, 245, 0.4) !important; // Subtle border
  }

  .bg-white {
    background-color: #f0f2f5 !important;
  }

  .text {
    color: #e1e3e6 !important; // Slightly off-white for comfort
  }

  .bg-balanced {
    background-color: #1d1f25 !important; // Balanced middle tone
  }
  .button {
    background-color: #1d1f25 !important;
    &:hover {
      background-color: #2d2f36 !important;
    }
  }
}

[data-bs-theme='light'] {
  .bg-main {
    background: linear-gradient(
      to right,
      #f4f6f8,
      #ffffff
    ) !important; // Subtle gradient
  }

  .bg-surface {
    background-color: #edf0f4 !important; // Softer surface
  }

  .bg-surface-darker {
    background-color: #e4e7eb !important; // Subtle contrast
  }

  .bg-hover {
    background-color: #dce0e5 !important; // Gentle hover state
  }

  .text-primary {
    color: #2c3038 !important; // Softer black for better readability
  }

  .border-success {
    border-color: rgba(44, 48, 56, 0.15) !important; // Subtle border
  }

  .bg-white {
    background-color: #2c3038 !important;
  }

  .text {
    color: #2c3038 !important; // Softer black for comfort
  }

  .bg-balanced {
    background-color: #eef0f2 !important; // Balanced middle tone
  }
  .button {
    background-color: #f8f9fa !important;
    &:hover {
      background-color: #e9ecef !important;
    }
  }
}
