@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

// Theme Colors
$theme-colors: (
  dark: (
    bg-main: #1a1a1a,
    bg-surface: #242424,
    bg-surface-darker: #121212,
    bg-hover: #2d2d2d,
    border-accent: #000000,
  ),
  light: (
    bg-main: #ffffff,
    bg-surface: #f8f9fa,
    bg-surface-darker: #e9ecef,
    bg-hover: #ced4da,
    bg-disabled: #adb5bd,
    text-warning: #d39e00,
    border-accent: #000000,
  ),
);

@function theme-color($theme, $color-key) {
  @return map-get(map-get($theme-colors, $theme), $color-key);
}

// Spacing Variables
:root {
  --section-spacing: 8rem;
  --content-spacing: 4rem;
  --element-spacing: 2rem;
}

.middle-section-wrapper {
  text-align: center;
}

.features-container {
  background-color: theme-color('dark', 'bg-main');

  // Hero Section
  .hero-section {
    padding: 4rem 0;
    margin-bottom: var(--section-spacing);

    .hero-content {
      padding-left: 7rem;
      max-width: 800px;

      @media (max-width: 768px) {
        padding-left: 2rem;
      }

      h1 {
        font-size: 3.2rem;

        font-weight: 800;
        line-height: 1.1;
        margin-bottom: 1rem;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        letter-spacing: -0.02em;

        @media (max-width: 768px) {
          font-size: 1.8rem;
        }
      }

      p {
        font-size: 1.3rem;
        margin-bottom: 1.2rem;
        line-height: 1.4;
        max-width: 450px;
        font-weight: 400;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      }

      .hero-buttons {
        display: flex;
        gap: 0.6rem;

        .btn {
          padding: 0.5rem 1.2rem;
          font-weight: 600;
          border-radius: 4px;
          transition: all 0.3s ease;
          font-size: 0.8rem;
          letter-spacing: 0.02em;

          &.btn-primary {
            border: none;

            &:hover {
              transform: scale(1.05);
              box-shadow: 0 0 15px rgba(45, 45, 45, 0.3);
            }
          }

          &.btn-secondary {
            border: 1px solid rgba(255, 255, 255, 0.8);
            position: relative;
            overflow: hidden;
            z-index: 1;

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }

  // Detailed Features Section
  .detailed-features {
    background-color: theme-color('dark', 'bg-main');
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-scrolling: touch;
    scrollbar-width: none;

    .feature-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3rem 0;
      position: relative;
      background-color: theme-color('dark', 'bg-main');

      &:first-child {
        scroll-snap-align: center;
      }

      .feature-content {
        display: flex;
        align-items: center;
        gap: 6rem;
        padding: 0 4rem;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        width: 100%;

        .feature-text {
          flex: 1;
          max-width: 600px;

          h2 {
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 2rem;
            color: var(--text-primary);
            line-height: 1.2;
            letter-spacing: -0.02em;
            opacity: 0;
            animation: fadeInUp 0.6s ease forwards;
          }

          p {
            font-size: 1.2rem;
            line-height: 1.7;
            color: var(--text-secondary);
            opacity: 0;
            animation: fadeInUp 0.6s 0.2s ease forwards;
          }
        }

        .feature-visual {
          flex: 1;

          .visual-placeholder {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
            opacity: 0;
            animation: fadeInUp 0.6s 0.4s ease forwards;

            &:hover {
              transform: translateY(-10px);
            }
          }
        }
      }
    }
  }
}

.snap-page {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.snap-section {
  min-height: 100vh; // Change from height to min-height
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overflow: hidden; // Add this to prevent content from overflowing
}

// Animations
@keyframes moveLeftRight {
  0% {
    transform: translateX(-50%) translateX(-50vw);
  }
  100% {
    transform: translateX(-50%) translateX(50vw);
  }
}

// ... (previous code remains the same)

// Animations
@keyframes moveLeftRight {
  0% {
    transform: translateX(-50%) translateX(-50vw);
  }
  100% {
    transform: translateX(-50%) translateX(50vw);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media Queries
@media (max-width: 768px) {
  .detailed-features {
    .feature-container {
      .feature-content {
        flex-direction: column;
        padding: 2rem;
        gap: 3rem;
        position: relative;
        transform: none;
        top: auto;
        left: auto;

        .feature-text {
          text-align: center;

          h2 {
            font-size: 2rem;
          }

          p {
            font-size: 1.1rem;
            margin: 0 auto;
          }
        }

        .feature-visual {
          width: 100%;

          .visual-placeholder {
            height: 300px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .detailed-features {
    .feature-container {
      .feature-content {
        gap: 3rem;
        padding: 0 2rem;

        .feature-text {
          max-width: 500px;

          h2 {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
  .lead {
    span {
      display: inline-flex;
      align-items: center;
      gap: 0.1rem; // Reduced gap for even tighter spacing

      .Logo {
        position: relative;
        top: -2px;
        margin: 0; // Removed horizontal margin
      }
    }
  }
  // Add these new mobile-specific styles at the end of your SCSS file

  @media (max-width: 768px) {
    .hero-section {
      padding: 2rem 0;

      .hero-content {
        padding-left: 1rem;
        padding-right: 1rem;

        h1 {
          font-size: 2rem;
        }

        h2 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .detailed-features {
      .feature-container {
        min-height: auto;
        height: auto;
        padding: 2rem 0;

        .feature-content {
          position: static;
          transform: none;
          flex-direction: column;
          gap: 2rem;
          padding: 0 1rem;

          .feature-text {
            text-align: center;

            h2 {
              font-size: 1.8rem;
              margin-bottom: 1rem;
            }

            p {
              font-size: 1rem;
              line-height: 1.5;
            }
          }

          .feature-visual {
            width: 100%;

            .visual-placeholder {
              height: 200px;

              img {
                max-width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    .snap-section {
      min-height: auto;
      height: auto;
      scroll-snap-align: start;
    }
  }

  .mobile-img {
    @media (max-width: 768px) {
      max-height: 200px;
      width: auto;
    }
  }
  // Add this for the AnimatedPointCloudVisualization
  .animated-point-cloud {
    width: 100%;
    height: 100%;
    max-height: 400px;

    @media (max-width: 768px) {
      max-height: 300px;
    }
  }
  .feature-visual img,
  .animated-cloud-container {
    max-width: 100%;
    height: auto;
  }
  .detailed-features .feature-container.controlled-agi-section {
    .feature-content {
      display: flex;
      align-items: center;
      gap: 3rem; // Reduced gap to allow for larger image

      .feature-text {
        flex: 1;
        max-width: 40%;
      }

      .feature-visual {
        flex: 1.5;

        img {
          width: 100%;
          height: auto;
          max-height: 600px; // Increased from 520px
          object-fit: contain;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .detailed-features .feature-container.controlled-agi-section {
      .feature-content {
        flex-direction: column;
        gap: 2rem;

        .feature-text,
        .feature-visual {
          flex: 1;
          max-width: 100%;
        }

        .feature-visual img {
          max-height: 300px;
        }
      }
    }
  }
}
