.warning-message {
  display: flex;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.05);
  border-left: 4px solid #ffd700;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  .warning-icon {
    svg {
      width: 30px;
      height: 30px;
      fill: #ffd700;
    }
  }
}

.code-block {
  border-radius: 6px;
  padding: 15px;
  position: relative;
  overflow: hidden;

  code {
    font-size: 0.9rem;
    /* standart text color like ea80bd */
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

[data-theme='dark'] {
  .code-block {
    background-color: rgba(255, 255, 255, 0.1);
    code {
      color: #818cf8;
    }
  }
  .requirement-item {
    --border-color: rgba(255, 255, 255, 0.1);
  }
}

[data-theme='light'] {
  .code-block {
    background-color: rgba(0, 0, 0, 0.05);
    code {
      color: #4f46e5;
    }
  }

  .requirement-item {
    --border-color: rgba(0, 0, 0, 0.1);
  }
}

.requirements-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.requirement-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color);

  &:last-child {
    border-bottom: none;
  }

  .requirement-label {
    flex: 0 0 100px;
    font-weight: 600;
  }
}
