// Footer styles
.footer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  // Theme variations
  &.bg-dark {
    background-color: #212529;
    color: white;
    a {
      color: white;
    }
  }

  &.bg-light {
    background-color: #f8f9fa;
    color: #212529;
    a {
      color: #212529;
    }
  }

  // Footer content wrapper
  .footer-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
  }

  // Sponsors section
  .sponsors-section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4.5rem 0;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
      text-align: center;
    }

    .sponsor-logo {
      max-width: 180px;
      height: auto;
      opacity: 0.8;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  // Main footer section
  .main-footer {
    width: 100%;
    padding: 2rem 0;

    .main-footer-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .company-info-col {
      flex: 1 1 300px;
      margin-bottom: 2rem;
    }

    .quick-links-col {
      flex: 0 1 200px;
      margin-bottom: 2rem;
    }

    .footer-logo-section {
      .footer-logo {
        max-width: 100px;
        height: auto;
        margin-bottom: 1.5rem;
      }

      .company-description {
        font-size: 1rem;
        line-height: 1.6;
        margin: 0;
        width: 50%;
      }
    }

    h5 {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }

    // Navigation links
    .list-unstyled {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 0.5rem;

        a {
          font-size: 1rem;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // Social links
  .social-links {
    margin: 2rem 0;

    a {
      margin: 0 1rem;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-3px);
      }
    }
  }

  // Copyright section
  .copyright {
    font-size: 0.9rem;
    opacity: 0.8;
    margin: 0;
    padding: 1rem 0;
  }

  // Media queries
  @media (max-width: 768px) {
    .sponsors-section {
      padding: 4.5rem 0;

      .sponsor-logo {
        max-width: 120px;
      }

      h3 {
        font-size: 1.3rem;
      }
    }

    .main-footer {
      padding: 1rem 0;

      .company-info-col,
      .quick-links-col {
        flex-basis: 100%;
      }

      .footer-logo-section .company-description {
        font-size: 0.9rem;
      }
    }

    .social-links {
      margin: 1rem 0;

      a {
        margin: 0 0.5rem;
      }
    }

    .copyright {
      font-size: 0.8rem;
    }
  }
}

// Variables
:root {
  --icon-color: #000000;
}

[data-theme='dark'] {
  --icon-color: #ffffff;
}

.lower-heading {
  margin-bottom: 2rem !important;
}
