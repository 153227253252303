// _variables.scss or your main style file
:root {
  // Default light theme variables
  --bg-main: #ffffff;
  --bg-surface: #f8f9fa;
  --bg-surface-darker: #e9ecef;
  --bg-hover: #ced4da;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --border-color: rgba(0, 0, 0, 0.1);

  --primary-green: #28a745;
  --primary-green-rgb: 40, 167, 69;
  --primary-green-dark: #218838;
  --primary-green-light: #34ce57;
  --primary-green-subtle: rgba(40, 167, 69, 0.1);
}

[data-theme='dark'] {
  --bg-main: #1a1a1a;
  --bg-surface: #242424;
  --bg-surface-darker: #121212;
  --bg-hover: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.85);
  --border-color: rgba(255, 255, 255, 0.1);
}

// Custom spacing classes
.mb-6 {
  margin-bottom: 6rem !important;
}
.mb-7 {
  margin-bottom: 8rem !important;
}
.pb-6 {
  padding-bottom: 6rem !important;
}
.pb-7 {
  padding-bottom: 10rem !important;
}

// Responsive spacing for larger screens
@media (min-width: 768px) {
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 8rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 8rem !important;
  }
}

.pt-6 {
  padding-top: 10rem !important;
}
.pt-7 {
  padding-top: 8rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mt-7 {
  margin-top: 8rem !important;
}
.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.py-7 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

// Responsive spacing for larger screens
@media (min-width: 768px) {
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 8rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 8rem !important;
  }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
}
