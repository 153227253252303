// card.scss

// Add this wrapper class at the top
.pricing-card-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pricing-header {
  padding: 1.5rem;

  .title-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;

    .icon-wrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      flex-shrink: 0;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
      color: var(--text-primary);
    }
  }

  // Add this section for consistent spacing
  .pricing-section {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pricing-toggle {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;

    .toggle-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .price-block {
        flex: 1;
        text-align: center;
        padding: 0.5rem;

        .price-label {
          display: block;
          font-size: 0.875rem;
          color: var(--bs-secondary-color);
          margin-bottom: 0.25rem;
        }

        .price {
          position: relative;
          display: inline-flex;
          align-items: flex-start;

          .currency {
            font-size: 1.2rem;
            margin-right: 2px;
          }

          .amount {
            font-size: 2rem;
            font-weight: bold;
          }

          .cents {
            position: absolute;
            bottom: -0.5rem; // Move the cents down
            right: -1rem; // Adjust horizontal position as needed
            font-size: 1rem;
          }
        }
      }

      .divider {
        width: 1px;
        height: 40px;
        background-color: var(--bs-border-color);
      }
    }
  }
}

.pricing-body {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  .features-list {
    margin: 0 0 1.5rem;
    padding: 0;
    list-style: none;
    flex: 1;

    li {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 1rem;
      color: var(--text-primary);

      i {
        color: var(--success);
        font-size: 1.1rem;
        flex-shrink: 0;
      }

      .feature-label {
        color: var(--text-secondary);
        flex: 1;
      }

      .feature-value {
        font-weight: 500;
        color: var(--text-primary);
      }
    }
  }
}

.pricing-toggle-placeholder {
  height: 116px; // Adjust this value to match the height of your pricing-toggle section
  margin-top: 1rem; // Match the margin of pricing-toggle
}

.pricing-toggle {
  height: 116px; // Set explicit height to match placeholder
  margin-top: 1rem;
  // ... rest of your pricing-toggle styles
}

// Dark mode support
:root {
  --text-primary: #2c3e50;
  --text-secondary: #64748b;
  --bg-secondary: #f8fafc;
  --border-color: #e2e8f0;
  --success: #10b981;
}

[data-theme='dark'] {
  --text-primary: #f1f5f9;
  --text-secondary: #94a3b8;
  --bg-secondary: #1e293b;
  --border-color: #334155;
  --success: #34d399;
}

.popular-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: var(--success);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 600;
}
