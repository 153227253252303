.card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: var(--bs-body-color);
  transform: translateY(20px);
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.1s;
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ease-out;

  .card-body {
    padding: 1.5rem;
    font-size: 1.2rem;
  }

  &[type='button'] {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    width: 100%;
    display: block;
  }
}
