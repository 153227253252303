.images-stack {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 600px;
  perspective: 1500px;
  transform-style: preserve-3d;
  /* margin: 100px auto; */
}

.image-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  transform-origin: center 120%;
  transition: transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);
  backface-visibility: hidden;
  will-change: transform;
  cursor: pointer;

  // Default positions
  &[data-position='0'] {
    transform: translateY(0) translateZ(100px) rotateX(33deg);
    z-index: 3;
    filter: brightness(1);
  }

  &[data-position='1'] {
    transform: translateY(40px) translateZ(50px) rotateX(33deg);
    z-index: 2;
    filter: brightness(0.9);
  }

  &[data-position='2'] {
    transform: translateY(80px) translateZ(0) rotateX(33deg);
    z-index: 1;
    filter: brightness(0.8);
  }

  // Animation states
  &.animating {
    transition: none;

    &[data-position='0'] {
      animation: moveToBottom 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
      z-index: 1; // Start above, then go behind
    }

    &[data-position='1'] {
      animation: moveToTop 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    }

    &[data-position='2'] {
      animation: moveToMiddle 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both;
    }
  }

  // Hover effect for top card
  &[data-position='0']:hover {
    transform: translateY(-20px) translateZ(120px) rotateX(30deg);
  }
}

@keyframes moveToBottom {
  0% {
    transform: translateY(0) translateZ(100px) rotateX(33deg);
    filter: brightness(1);
    z-index: 3;
  }

  20% {
    transform: translateY(-30px) translateZ(100px) rotateX(33deg) rotateZ(-8deg);
    filter: brightness(1.1);
    z-index: 3;
  }

  40% {
    transform: translateY(100px) translateZ(-100px) rotateX(45deg)
      rotateZ(-4deg);
    filter: brightness(0.9);
    z-index: 1;
  }

  70% {
    transform: translateY(140px) translateZ(-50px) rotateX(40deg) rotateZ(-2deg);
    filter: brightness(0.85);
    z-index: 1;
  }

  100% {
    transform: translateY(80px) translateZ(0) rotateX(33deg) rotateZ(0);
    filter: brightness(0.8);
    z-index: 1;
  }
}

@keyframes moveToTop {
  0% {
    transform: translateY(40px) translateZ(50px) rotateX(33deg);
    filter: brightness(0.9);
  }

  50% {
    transform: translateY(20px) translateZ(75px) rotateX(33deg);
    filter: brightness(0.95);
  }

  100% {
    transform: translateY(0) translateZ(100px) rotateX(33deg);
    filter: brightness(1);
  }
}

@keyframes moveToMiddle {
  0% {
    transform: translateY(80px) translateZ(0) rotateX(33deg);
    filter: brightness(0.8);
  }

  50% {
    transform: translateY(60px) translateZ(25px) rotateX(33deg);
    filter: brightness(0.85);
  }

  100% {
    transform: translateY(40px) translateZ(50px) rotateX(33deg);
    filter: brightness(0.9);
  }
}

.stacked-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}
