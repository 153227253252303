// src/pages/Pricing/Pricing.scss
.pricing-container {
  background: var(--bs-body-bg);
  padding: 4rem 0;

  // Header styles matching the previous code
  h4 {
    color: var(--primary-green);
    font-size: 1.1rem;

    margin-bottom: 0.5rem;
  }

  // Paragraph styles matching the previous code
  p {
    color: var(--bs-body-color);
    margin: 0;
    font-size: 1.25rem;
    opacity: 0.9;
  }

  // Bold paragraph style
  .bold-p {
    font-weight: 600;
  }

  // Lead paragraph style
  .lead {
    color: var(--bs-body-color);
    opacity: 0.85;
    font-size: 1.1rem;
    line-height: 1.7;
    animation: fadeInUp 0.6s ease-out forwards;
    animation-delay: 0.2s;
  }

  .pricing-badge {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: var(
      --primary-green-subtle
    ); // Using your custom green with opacity
    color: var(--primary-green); // Using your custom green
    border-radius: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    // Centering properties
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .pricing-title {
    text-align: center;
    font-size: 3rem; // Updated to match display-4
    font-weight: 900; // Updated to match display-4
    letter-spacing: -0.5px; // Added to match display-4
    margin: 0 auto 2rem auto;
    width: 700px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    color: var(--bs-body-color);

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 1rem;
      font-size: 2.5rem; // Matches the mobile display-4 size
    }
  }

  .pricing-subtitle {
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem; // Updated to match p style
    color: var(--bs-body-color);
    opacity: 0.9; // Updated to match p style
    line-height: 1.6;
    margin: 0 auto 4rem auto;
    width: 600px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  .row {
    margin-bottom: 3rem;
  }
}

// [data-bs-theme='dark'] {
//   .pricing-container {
//     background: linear-gradient(to right, #1a1a1a, #242424);
//   }
// }

[data-bs-theme='light'] {
  .pricing-container {
    background: var(--bs-body-bg);
  }

  [data-bs-theme='dark'] {
    .pricing-container {
      background: linear-gradient(
        to bottom,
        rgba(30, 30, 30, 0.8),
        rgba(20, 20, 20, 1)
      );

      .pricing-header {
        background: linear-gradient(
          180deg,
          rgba(var(--bs-primary-rgb), 0.1) 0%,
          rgba(var(--bs-primary-rgb), 0) 100%
        );
      }

      .pricing-table-section {
        background: rgba(255, 255, 255, 0.02);

        .table-container {
          background: rgba(30, 30, 30, 0.9);
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  [data-bs-theme='light'] {
    .pricing-container {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8),
        rgba(250, 250, 250, 1)
      );

      .pricing-table-section {
        background: rgba(var(--bs-primary-rgb), 0.02);

        .table-container {
          background: #ffffff;
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
          border: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
