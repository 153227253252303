/* Button.css */
.btn {
  /* Add any custom base styles */
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

/* Custom hover effects */
.btn:hover {
  /* transform: translateY(-1px); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Custom colors if needed */
.btn-primary {
  background-color: #28a745;
  border-color: #000;
}

.custom-button {
  // Base styles
  color: #ffffff;
  border: 1px solid rgba(40, 167, 69, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;

  // Normal state with subtle depth
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.05);

  // Hover effects
  &:hover {
    // Enhanced pop-out effect
    transform: translateY(-4px);

    // Refined glowing effect
    box-shadow:
      0 8px 20px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(40, 167, 69, 0.2),
      0 0 20px rgba(40, 167, 69, 0.3),
      0 0 40px rgba(40, 167, 69, 0.1);

    // Subtle border glow
    border-color: rgba(40, 167, 69, 0.4);

    // Optional: subtle text glow
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
  }

  // Active state (when clicking)
  &:active {
    transform: translateY(-2px);
    box-shadow:
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(40, 167, 69, 0.2),
      0 0 15px rgba(40, 167, 69, 0.2);
  }

  // Focus state
  &:focus {
    outline: none;
    box-shadow:
      0 0 0 2px rgba(40, 167, 69, 0.3),
      0 4px 12px rgba(0, 0, 0, 0.2);
  }
}
/* Add more custom styles as needed */
