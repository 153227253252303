// src/components/PriceTable/PriceTable.scss
.price-table-container {
  width: 100%;
  overflow-x: auto;
  margin: 2rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .price-table {
    width: 100%;
    border-collapse: collapse;
    background: var(--bs-body-bg);
    font-size: 0.9rem;
    color: var(--bs-body-color);

    th,
    td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid var(--bs-border-color);
    }

    th {
      background: #ffffff;
      font-weight: 700;
      white-space: nowrap;
      color: var(--bs-success);
      font-size: 0.95rem;
      letter-spacing: 0.3px;
      border-bottom: 2px solid rgba(var(--bs-success-rgb), 0.2);
    }

    td {
      white-space: nowrap;
    }

    .provider-cell {
      background: #ffffff;
      font-weight: 700;
      color: var(--bs-success);
      border-right: 2px solid rgba(var(--bs-success-rgb), 0.2);
      font-size: 0.95rem;
    }

    tbody {
      tr {
        &:hover {
          background: rgba(var(--bs-success-rgb), 0.02);
        }
      }
    }

    @media (max-width: 768px) {
      font-size: 0.8rem;

      th,
      td {
        padding: 0.75rem;
      }
    }
  }
}

[data-bs-theme='dark'] {
  .price-table-container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    .price-table {
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(10px);
      color: #e2e2e2;

      th {
        background: rgba(255, 255, 255, 0.05);
        color: var(--bs-success);
        border-bottom: 2px solid rgba(var(--bs-success-rgb), 0.2);
      }

      td {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: #e2e2e2;
      }

      .provider-cell {
        background: rgba(255, 255, 255, 0.05);
        color: var(--bs-success);
        border-right: 2px solid rgba(var(--bs-success-rgb), 0.2);
      }

      tbody {
        tr {
          transition: background-color 0.2s ease;

          &:hover {
            background: rgba(var(--bs-success-rgb), 0.1);
          }
        }
      }
    }
  }
}
