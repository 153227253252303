.faq-page {
  .bg-main {
    margin-top: -1px;
  }
}
.FAQ-badge {
  display: inline-block;
  padding: 0.5rem 1.5rem;
}
.faq-item {
  padding-bottom: 1rem;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
  text-decoration: none;
  font-weight: bolder !important;
}

.faq-icon {
  font-size: 1.5rem;
  font-weight: bold;

  // Added property
  transition: transform 0.3s ease-out;

  &.open {
    transform: rotate(45deg);
  }
}

.faq-answer {
  font-size: large;

  text-align: center !important;

  // Added properties
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
  opacity: 0;
  transform: translateY(-10px);

  &.open {
    margin-top: 30px;
    max-height: 1000px; // Adjust this value based on your content
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.faq-item {
  padding: 2rem !important;
}
.support-section {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}
.justify-content-center {
  margin-top: 100px !important;
}
.faq-card {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; // Transition for card movement and opacity
  position: relative;

  &.open {
    transform: translateY(
      10px
    ); // Adjust the value as needed for the downward shift
    opacity: 1; // Ensure the card is fully visible
  }
}
