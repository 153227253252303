// .main-content {
//   min-height: calc(100vh - 80px); // Adjust based on your navbar height
// }

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}

.main-content {
  flex: 1;
}

.navbar {
  position: fixed !important; // Use !important to override any other styles
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

// Add this to account for the fixed navbar
body {
  padding-top: 76px; // Adjust this value based on your navbar height
}
// If you're using sticky scroll in your Home component,
// make sure the home container takes full height
.home-container {
  height: 100%;
  overflow-y: auto;
}
// Remove this
html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
